import React from "react";
import Grid from '@mui/material/Grid';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';

const ControlPanel = ({angles, dhParams, setAngles, setDhParams}) => {
    const handleSliderChange = (index, newValue) => {
        const newAngles = [...angles];
        newAngles[index] = newValue;
        setAngles(newAngles);
    };
    
    const handleDhParamChange = (index, key, value) => {
        const newDhParams = [...dhParams];
        newDhParams[index][key] = parseFloat(value);
        setDhParams(newDhParams);
    };

    return (
        <Grid
        item
        xs={3}
        style={{
            backgroundColor: '#333',
            padding: 20,
            color: 'white',
        }}
        >
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '50%', overflow: 'auto' }}>
        <TableContainer component={Paper} sx={{ backgroundColor: '#444', color: 'white' }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
        <TableRow>
        <TableCell style={{ color: 'white' }}>Joint</TableCell>
        <TableCell style={{ color: 'white' }}>Theta (rad)</TableCell>
        <TableCell style={{ color: 'white' }}>a (m)</TableCell>
        <TableCell style={{ color: 'white' }}>d (m)</TableCell>
        <TableCell style={{ color: 'white' }}>Alpha (rad)</TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
        {dhParams.map((param, index) => (
            <TableRow key={index}>
            <TableCell style={{ color: 'white' }}>{index + 1}</TableCell>
            <TableCell>
            <TextField
            value={param.theta}
            onChange={(e) => handleDhParamChange(index, 'theta', e.target.value)}
            type="number"
            inputProps={{ step: 0.01 }}
            variant="standard"
            sx={{ input: { color: 'white' } }}
            />
            </TableCell>
            <TableCell>
            <TextField
            value={param.a}
            onChange={(e) => handleDhParamChange(index, 'a', e.target.value)}
            type="number"
            inputProps={{ step: 0.01 }}
            variant="standard"
            sx={{ input: { color: 'white' } }}
            />
            </TableCell>
            <TableCell>
            <TextField
            value={param.d}
            onChange={(e) => handleDhParamChange(index, 'd', e.target.value)}
            type="number"
            inputProps={{ step: 0.01 }}
            variant="standard"
            sx={{ input: { color: 'white' } }}
            />
            </TableCell>
            <TableCell>
            <TextField
            value={param.alpha}
            onChange={(e) => handleDhParamChange(index, 'alpha', e.target.value)}
            type="number"
            inputProps={{ step: 0.01 }}
            variant="standard"
            sx={{ input: { color: 'white' } }}
            />
            </TableCell>
            </TableRow>
        ))}
        </TableBody>
        </Table>
        </TableContainer>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '50%', overflow: 'auto' }}>
        {angles.map((angle, index) => (
            <Slider
            key={index}
            value={angle}
            onChange={(e, newValue) => handleSliderChange(index, newValue)}
            min={-Math.PI}
            max={Math.PI}
            step={0.01}
            valueLabelDisplay="auto"
            sx={{
                marginBottom: 2,
                color: 'white',
                '& .MuiSlider-thumb': {
                    backgroundColor: 'white',
                },
                '& .MuiSlider-rail': {
                    backgroundColor: '#888',
                },
                '& .MuiSlider-track': {
                    backgroundColor: 'white',
                },
            }}
            />
        ))}
        </Box>
        </Grid>
    );
}

export default ControlPanel;