import React, { useState, useEffect } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { VRMLLoader } from 'three/addons/loaders/VRMLLoader.js';
import * as THREE from 'three';
import link0 from '../assets/model/ur5/link0.wrl'
import link1 from '../assets/model/ur5/link1.wrl'
import link2 from '../assets/model/ur5/link2.wrl'
import link3 from '../assets/model/ur5/link3.wrl'
import link4 from '../assets/model/ur5/link4.wrl'
import link5 from '../assets/model/ur5/link5.wrl'
import link6 from '../assets/model/ur5/link6.wrl'


// DH 파라미터를 변환 행렬로 변환하는 함수
const computeDHMatrix = (theta, d, a, alpha) => {
    const matrix = new THREE.Matrix4();
    
    // Transformation matrix 계산
    matrix.set(
        Math.cos(theta), -Math.sin(theta) * Math.cos(alpha), Math.sin(theta) * Math.sin(alpha), a * Math.cos(theta),
        Math.sin(theta), Math.cos(theta) * Math.cos(alpha), -Math.cos(theta) * Math.sin(alpha), a * Math.sin(theta),
        0, Math.sin(alpha), Math.cos(alpha), d,
        0, 0, 0, 1
    );
    // matrix.set(
    //     Math.cos(theta), -Math.sin(theta), 0, a,
    //     Math.sin(theta) * Math.cos(alpha), Math.cos(theta) * Math.cos(alpha), -Math.sin(alpha), -d * Math.sin(alpha),
    //     Math.sin(theta) * Math.sin(alpha), Math.cos(theta) * Math.sin(alpha), Math.cos(alpha), d * Math.cos(alpha),
    //     0, 0, 0, 1
    // );
    
    return matrix;
};

const Foo = ({setBodies}) => {
    const { scene } = useThree();
    useEffect(() => {
        const bodies = {};
        const loadVRML = async () => {
            let loader = new VRMLLoader();
            const links = [link0, link1, link2, link3, link4, link5, link6];
            const promises = links.map((link, i) => {
                return new Promise((resolve, reject) => {
                    try {
                        loader.load(
                            link,
                            (object) => { 
                                bodies[`link${i}`] = object;
                                scene.add(object);
                                resolve();
                            },
                            undefined,
                            (error) => { throw(error); }
                        );
                    } catch (error) {
                        reject(error);
                    }
                })
            })
            Promise.all(promises);
        };
        loadVRML().then(() => { setBodies(bodies); });
    }, []);
}

const RobotArm = ({ angles, dhParams }) => {
    const [bodies, setBodies] = useState({});
    
    useEffect(() => {
        const f0 = new THREE.Matrix4();
        f0.set(1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1);
        if(Object.keys(bodies).length < 7) return;
        
        const transform0to1 = computeDHMatrix(dhParams[0].theta + angles[0], dhParams[0].d, dhParams[0].a, dhParams[0].alpha);
        const transform1to2 = computeDHMatrix(dhParams[1].theta + angles[1], dhParams[1].d, dhParams[1].a, dhParams[1].alpha);
        const transform2to3 = computeDHMatrix(dhParams[2].theta + angles[2], dhParams[2].d, dhParams[2].a, dhParams[2].alpha);
        const transform3to4 = computeDHMatrix(dhParams[3].theta + angles[3], dhParams[3].d, dhParams[3].a, dhParams[3].alpha);
        const transform4to5 = computeDHMatrix(dhParams[4].theta + angles[4], dhParams[4].d, dhParams[4].a, dhParams[4].alpha);
        const transform5to6 = computeDHMatrix(dhParams[5].theta + angles[5], dhParams[5].d, dhParams[5].a, dhParams[5].alpha);
        
        const f1 = transform0to1.premultiply(f0);
        const f2 = transform1to2.premultiply(f1);
        const f3 = transform2to3.premultiply(f2);
        const f4 = transform3to4.premultiply(f3);
        const f5 = transform4to5.premultiply(f4);
        const f6 = transform5to6.premultiply(f5);
        
        bodies["link0"].matrix.copy(f0); 
        bodies["link1"].matrix.copy(f1); 
        bodies["link2"].matrix.copy(f2); 
        bodies["link3"].matrix.copy(f3); 
        bodies["link4"].matrix.copy(f4); 
        bodies["link5"].matrix.copy(f5); 
        bodies["link6"].matrix.copy(f6); 
        
        bodies["link0"].matrix.decompose(bodies["link0"].position, bodies["link0"].quaternion, bodies["link0"].scale);
        bodies["link1"].matrix.decompose(bodies["link1"].position, bodies["link1"].quaternion, bodies["link1"].scale);
        bodies["link2"].matrix.decompose(bodies["link2"].position, bodies["link2"].quaternion, bodies["link2"].scale);
        bodies["link3"].matrix.decompose(bodies["link3"].position, bodies["link3"].quaternion, bodies["link3"].scale);
        bodies["link4"].matrix.decompose(bodies["link4"].position, bodies["link4"].quaternion, bodies["link4"].scale);
        bodies["link5"].matrix.decompose(bodies["link5"].position, bodies["link5"].quaternion, bodies["link5"].scale);
        bodies["link6"].matrix.decompose(bodies["link6"].position, bodies["link6"].quaternion, bodies["link6"].scale);
    }, [angles, dhParams])
    
    return (
        <Canvas style={{ height: '100%', width: '100%' }}>
        <Foo setBodies={setBodies}/>
        <ambientLight intensity={0.5} />
        <pointLight position={[10, 10, 10]} />
        <OrbitControls />
        </Canvas>
    );
};

export default RobotArm;
