import React, { useCallback, useState } from 'react';
import ControlPanel from './components/ControlPanel';
import RobotArm from './components/RobotArm';
import Grid from '@mui/material/Grid';

const App = () => {
    const [angles, setAngles] = useState([0, 0, 0, 0, 0, 0]);
    // ur20 dh
    // const [dhParams, setDhParams] = useState([
    //     { theta: 0, a: 0, d: 0.2363, alpha: Math.PI / 2 },
    //     { theta: 0, a: -0.8620, d: 0, alpha: 0 },
    //     { theta: 0, a: -0.7287, d: 0, alpha: 0 },
    //     { theta: 0, a: 0, d: 0.2010, alpha: Math.PI / 2 },
    //     { theta: 0, a: 0, d: 0.1593, alpha: -Math.PI / 2 },
    //     { theta: 0, a: 0, d: 0.1543, alpha: 0 },
    // ]);
    // ur5
    const [dhParams, setDhParams] = useState([
        { theta: 0, a: 0, d: 0.089159, alpha: Math.PI / 2 },
        { theta: 0, a: -0.425, d: 0, alpha: 0 },
        { theta: 0, a: -0.39225, d: 0, alpha: 0 },
        { theta: 0, a: 0, d: 0.10915, alpha: Math.PI / 2 },
        { theta: 0, a: 0, d: 0.09465, alpha: -Math.PI / 2 },
        { theta: 0, a: 0, d: 0.0823, alpha: 0 },
    ]);
    // gp35l

    const gatedSetAngles = useCallback((value) => {
      setAngles(value);
    }, []);

    const gatedSetDhParams= useCallback((value) => {
      setDhParams(value);
    }, []);

  return (
    <Grid container style={{ height: '100vh' }}>
    <ControlPanel angles={angles} dhParams={dhParams} setAngles={gatedSetAngles} setDhParams={gatedSetDhParams}></ControlPanel>
    <Grid item xs={9}>
    <RobotArm angles={angles} dhParams={dhParams}/>
    </Grid>
    </Grid>
  );
};

export default App;
